import Reportings from '../index'
import ReportItem from '../report'

export default [
  {
    name: 'Reportings',
    component: Reportings,
    path: '/reportings',
    meta: {
      slug: 'reports',
      main_menu: true,
      page: 'reports',
      permissionKey: 'ReportsMenu'
    }
  },
  {
    name: 'ReportItem',
    path: '/reportings/:id/:name',
    component: ReportItem,
    meta: {
      page: 'reports',
      breadcrumb: [
        {
          name: 'reports',
          to: 'Reportings'
        },
        {
          withParams: true,
          param: 'name',
          to: null
        }
      ]
    }
  }
]
