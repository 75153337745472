import HTTP from '@/utils/http'
import requestHandler from '@/utils/requestHandler'

export default {
  namespaced: true,

  state: {
    ReportGroups: []
  },

  getters: {
    ReportGroups: state => state.ReportGroups
  },

  actions: {

    async fetchReportGroups({ commit }) {
      const results = await requestHandler({
        title: 'fetchReportGroups',
        methodName: 'fetchReportGroups',
        method: () => {
          return HTTP.get('Reporting/group/all')
        },
        success: result => {
          commit('SET_REPORT_GROUPS', result.data.reportGroupList)
        }
      })
      return results
    },
    async fetchReportGroupsPermitted({ commit }) {
      const results = await requestHandler({
        title: 'fetchReportGroups',
        methodName: 'fetchReportGroups',
        method: () => {
          return HTTP.get('Reporting/group/all/permitted')
        },
        success: result => {
          commit('SET_REPORT_GROUPS', result.data.reportGroupList)
        }
      })
      return results
    }
  },

  mutations: {
    SET_REPORT_GROUPS(state, reportGroups) {
      state.ReportGroups = reportGroups.sort((a, b) => (a.priority > b.priority) ? 1 : -1)
      state.ReportGroups.forEach(child => child.reports.sort((a, b) => (a.priority > b.priority) ? 1 : -1))
    }
  }
}
